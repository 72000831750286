import * as Sentry from '@sentry/browser';
import firebase from 'firebase/app';

const budgetMixin = {
    methods: {
        async recalculateBudgetTotal(claim, addToBudget = true) {
            console.log('recalc add', addToBudget);
            let budget = claim.budget;
            let budgetSnapshot = null;

            // Budget has been added to claims as a reverse reference, but may not be present on older claims.
            // If missing, we have to crawl over the claim user's data to find the budget for this claim.
            // This is an involved operation, so we'll return the budget ref to save against the claim for future calls.
            if (!budget) {
                console.log('DOING IT THE HARD WAY');
                // get plans for user
                let userRef = claim.user;
                if (typeof userRef === 'string' || userRef instanceof String) {
                    userRef = this.$fire.doc(userRef);
                }
                const userSnapshot = await userRef.get();
                const planRefs = userSnapshot.get('plans');
                // for each plan, get its budgets
                for (const planRef of planRefs) {
                    if (!budget) {
                        const planSnapshot = await planRef.get();
                        const planData = planSnapshot.get('data');
                        // for each budget, get its claims
                        for (const data of planData) {
                            budgetSnapshot = await data.budget.get();
                            const claimRefs = budgetSnapshot.get('claims');
                            // check claims for match
                            if (claimRefs.some((c) => c.id === claim.id)) {
                                budget = data.budget;
                                break;
                            }
                        }
                    }
                }
            }

            // if no budget was found, we can't proceed. Orphaned claim?
            if (!budget) {
                const message = `recalculateBudget: Claim ${claim.id} - no budget found`;
                console.log(message);
                Sentry.captureMessage(message);
                return null;
            }

            if (typeof budget === 'string' || budget instanceof String) {
                budget = this.$fire.doc(budget);
            }
            // update budget total
            let claimAmount = claim.total;
            if (!addToBudget) claimAmount = -claimAmount;
            console.log('Updating budget by', claimAmount);
            await budget.update({
                budget_spent:
                    firebase.firestore.FieldValue.increment(claimAmount),
            });

            return budget;
        },
    },
};

export default budgetMixin;
