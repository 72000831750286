var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "approval-action" },
    [
      _vm.statusChanging
        ? _c("div", { staticClass: "spinner" }, [
            _c("i", { staticClass: "el-icon-loading" }),
          ])
        : [
            _c("el-button", {
              attrs: {
                type: "danger",
                size: "mini",
                disabled: _vm.statusChanging,
                icon: "el-icon-close",
              },
              on: {
                click: function ($event) {
                  _vm.rejectDialogVisible = true
                },
              },
            }),
            _c("el-button", {
              attrs: {
                type: "success",
                size: "mini",
                disabled: _vm.statusChanging,
                icon: "el-icon-check",
              },
              on: { click: () => _vm.handleApprove(true) },
            }),
          ],
      _c(
        "el-dialog",
        {
          staticClass: "reject-dialog",
          attrs: {
            title: "Are you sure?",
            visible: _vm.rejectDialogVisible,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.rejectDialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Only reject this claim if something is wrong.")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.rejectDialogVisible = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.handleRejectClaim },
                },
                [_vm._v(" Reject claim ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }