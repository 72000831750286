<template>
    <div class="approval-action">
        <div v-if="statusChanging" class="spinner">
            <i class="el-icon-loading" />
        </div>
        <template v-else>
            <el-button
                type="danger"
                size="mini"
                :disabled="statusChanging"
                icon="el-icon-close"
                @click="rejectDialogVisible = true"
            />
            <el-button
                type="success"
                size="mini"
                :disabled="statusChanging"
                icon="el-icon-check"
                @click="() => handleApprove(true)"
            />
        </template>
        <el-dialog
            title="Are you sure?"
            :visible.sync="rejectDialogVisible"
            width="30%"
            center
            class="reject-dialog"
        >
            <span>Only reject this claim if something is wrong.</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="rejectDialogVisible = false">
                    Cancel
                </el-button>
                <el-button type="danger" @click="handleRejectClaim">
                    Reject claim
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import budgetMixin from '@/mixins/budget.mixin';
import claimMixin from '@/mixins/claim.mixin';
import firebase from 'firebase/app';

export default {
    name: 'approval-action',
    mixins: [budgetMixin, claimMixin],
    props: {
        claim: {
            type: [String, Object],
            default: null,
        },
    },
    data() {
        return {
            statusChanging: false,
            rejectDialogVisible: false,
        };
    },
    methods: {
        async handleApprove(approved) {
            this.statusChanging = true;
            const status = approved ? 'approved' : 'rejected';

            // if status has changed to or from rejected:
            if (
                (status === 'rejected' || this.claim.status === 'rejected') &&
                status !== this.claim.status
            ) {
                // recalculate total spent for this claim's budget
                await this.recalculateBudgetTotal(this.claim, approved);
            }

            const historyEntry = await this.createClaimHistoryEntry(
                firebase.auth().currentUser.uid,
                status
            );

            await this.$fire.doc(`claims/${this.claim.id}`).update({
                approval_status: status,
                history: firebase.firestore.FieldValue.arrayUnion(historyEntry),
            });

            this.$emit('change', this.claim, status);
            this.statusChanging = false;
        },

        async handleRejectClaim() {
            this.rejectDialogVisible = false;
            return this.handleApprove(false);
        },
    },
};
</script>

<style lang="scss" scoped>
.approval-action {
    width: 100%;
    text-align: center;
    > .el-button {
        padding-left: 8px;
        padding-right: 8px;
    }
}
.reject-dialog {
    ::v-deep .el-dialog__body {
        text-align: center;
    }
}
</style>
